<template>
    <div>
        <Main />
    </div>
</template>
<script>
  import Main from '../components/jackpot/Main.vue'
  export default {
        components: {
            Main
        }
  }
</script>
